import { ReactNode, useEffect, useState } from 'react';
import { useLogout } from 'kaf-kmf-login';
import { Heading, VerticalStack, HorizontalStack, HeroButton, Box, Text } from 'kindred-ui-kit/primitives';
import { Modal } from 'kindred-ui-kit/composition';
import { colors } from 'kindred-ui-kit/foundation';
import { ModalContent } from '../global/assets/ModalContent';
import TimeOutIcon from '../global/assets/TimeOutIcon';
import Logo from '../global/assets/Logo';
import { loadTranslations, useTranslation } from 'kmf-i18n';
import RGInfo from '../global/assets/RGInfo';
import { canRenderSessionTimeout } from './utils/canRenderSessionTimeout';

const SessionExpired = (): ReactNode => {
	const [modalState, setModalState] = useState(true);
	const [canRender, setCanRender] = useState(false);
	const { t } = useTranslation('session-expired');
	const { logout } = useLogout();

	const onCloseModal = () => {
		setModalState(false);
		window.location.href = window.location.origin;
	};

	const onLoginClick = () => {
		const isMyaccount = window.location.pathname.includes('myaccount');

		if (!isMyaccount) {
			const currentLocation = new URL(window.location.href);

			currentLocation.searchParams.set('showLogin', 'true');
			window.history.replaceState({}, '', currentLocation);
		}

		window.location.reload();
	};

	useEffect(() => {
		const allowRender = async () => {
			const isAllowed = await canRenderSessionTimeout();

			if (isAllowed) {
				setCanRender(true);
				//calling logout here makes sure that SSO cookie will be properly killed in case of KAF
				await logout({ forcePlatformLogout: true });
			}
			//do nothing if we're not allowed to render
		};

		allowRender();
	}, [setCanRender]);

	if (!canRender) {
		return null;
	}

	return (
		<Modal data-testid='session-expired-modal' isOpen={modalState} onClose={onCloseModal} disableCloseOnOverlayClick={true} width='500px' height='485px'>
			<ModalContent data-testid='session-expired-modal-content'>
				<VerticalStack justifyContent='center' alignItems='center' data-testid='session-expired-container'>
					<Box pt='xxl'>
						<Logo />
					</Box>
					<VerticalStack data-testid='session-expired-content' width='100%' height='100%' justifyContent='center' alignItems='center' mt='md'>
						<Heading data-testid='session-expired-heading' as='h3' size='heading-3' align='center'>
							{t('title')}
						</Heading>
						<TimeOutIcon fill={colors.brand} />
						<Box pl={{ xs: 'xs', sm: 'xl' }} pr={{ xs: 'xs', sm: 'xl' }}>
							<Text data-testid='session-expired-subheading' size='lg' bold align='center'>
								{t('header')}
							</Text>
						</Box>
						<Box pl={{ xs: 'xs', sm: 'xxl' }} pr={{ xs: 'xs', sm: 'xxl' }}>
							<RGInfo />
						</Box>
					</VerticalStack>
					<HorizontalStack width='100%' alignItems='center' mt='xxl' mb={'md'} pl={{ xs: 'xxxs', sm: 'xxl' }} pr={{ xs: 'xxxs', sm: 'xxl' }}>
						<HeroButton data-testid='session-expired-cta' onClick={onLoginClick} width='100%' size='lg'>
							{t('login_button')}
						</HeroButton>
					</HorizontalStack>
				</VerticalStack>
			</ModalContent>
		</Modal>
	);
};

SessionExpired.loader = () => Promise.all([loadTranslations('session-expired')]);

export default SessionExpired;
